// 
// timeline.scss
//

/************** Horizontal timeline **************/ 


.hori-timeline{
    .events{
        .event-list{
            text-align: center;
            display: block;

            .event-down-icon{
                position: relative;
                &::before{
                    content: "";
                    position: absolute;
                    width: 100%;
                    top: 16px;
                    left: 0;
                    right: 0;
                    border-bottom: 3px dashed $gray-300;
                }
                .down-arrow-icon{
                    position: relative;
                    background-color: $card-bg;
                    padding: 4px;
                }
            }

            &:hover{
                .down-arrow-icon{
                    animation: fade-down 1.5s infinite linear;
                }
            }

            &.active{
                .down-arrow-icon{
                    animation: fade-down 1.5s infinite linear;
                    &:before {
                        content: "\ec4c";
                    }
                }
            }
        }
    }
}


/************** vertical timeline **************/ 

.verti-timeline{
    border-left: 3px dashed $gray-300;
    margin: 0 10px;
    .event-list{
        position: relative;
        padding: 0px 0px 40px 30px;

        .event-timeline-dot{
            position: absolute;
            left: -9px;
            top: 0px;
            z-index: 9;
            font-size: 16px;
        }
        .event-content{
            position: relative;
            border: 2px solid $border-color;
            border-radius: 7px;
        }

        &.active{
            .event-timeline-dot{
                color: $primary;
            }
        }

        &:last-child{
            padding-bottom: 0px;
        }
    }
}

/************** VERTICAL CUSTOM timeline **************/ 


// colors


// timeline variables
$timeline-before-bg : #1FB8C8;
$timeline-body-bg: #41c3d1;
$timeline-body-h49-bg: #325c83;
$timeline-body-reply-bg: #f38066;
$timeline-body-round: 3px;
$timeline-body-shadow: 1px 3px 9px rgba(0,0,0, .1);



// page container
.timeline-container {
    max-width: 650px;
    
}

// typography
p {
    font-weight: 300;
    line-height: 1.5;
    font-size: 14px;
    opacity: .8;
}

// timeline style
.timeline {
    position: relative;
    padding-left: 4rem;
    margin: 0 0 0 30px;
    color: white;

    &:before {
        content: '';
        position: absolute;
        left: 0em;
        top: 0;
        width: 4px;
        height: 100%;
        background: $timeline-body-bg;
    }

    .timeline-container {
        position: relative;
        margin-bottom: 2.5rem;

        .timeline-icon {
            position: absolute;
            left: -88px;
            top: 4px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            text-align: center;
            font-size: 2rem;
            background: $secondary;

            i {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            img {
                width: 50%;
                height: 50%;
                
            }
        }

        .timeline-body-h49 {
            background: $timeline-body-h49-bg;
            border-radius: $timeline-body-round;
            padding: 20px 20px 15px;
            box-shadow: $timeline-body-shadow;

            &:before {
                content: '';
                background: inherit;
                width: 20px;
                height: 20px;
                display: block;
                position: absolute;
                left: -10px;
                transform: rotate(45deg);
                border-radius: 0 0 0 2px;
            }

            .timeline-title {
                margin-bottom: 1.4rem;

                .badge {
                    background: $secondary;
                    padding: 4px 8px;
                    border-radius: 3px;
                    font-size: 12px;
                    font-weight: bold;
                }
            }

            .timeline-subtitle {
                font-weight: 300;
                font-style: italic;
                opacity: .4;
                margin-top: 16px;
                font-size: 11px;
            }
        }

        .timeline-body-orange {
            background: $timeline-body-reply-bg;
            border-radius: $timeline-body-round;
            padding: 20px 20px 15px;
            box-shadow: $timeline-body-shadow;

            &:before {
                content: '';
                background: inherit;
                width: 20px;
                height: 20px;
                display: block;
                position: absolute;
                left: -10px;
                transform: rotate(45deg);
                border-radius: 0 0 0 2px;
            }

            .timeline-title {
                margin-bottom: 1.4rem;

                .badge {
                    background: $secondary;
                    padding: 4px 8px;
                    border-radius: 3px;
                    font-size: 12px;
                    font-weight: bold;
                }
            }

            .timeline-subtitle {
                font-weight: 300;
                font-style: italic;
                opacity: .4;
                margin-top: 16px;
                font-size: 11px;
            }
        }

        .timeline-body-cta {
            background: $timeline-body-bg;
            border-radius: $timeline-body-round;
            padding: 20px 20px 15px;
            box-shadow: $timeline-body-shadow;

            &:before {
                content: '';
                background: inherit;
                width: 20px;
                height: 20px;
                display: block;
                position: absolute;
                left: -10px;
                transform: rotate(45deg);
                border-radius: 0 0 0 2px;
            }

            .timeline-title {
                margin-bottom: 1.4rem;

                .badge {
                    background: $secondary;
                    padding: 4px 8px;
                    border-radius: 3px;
                    font-size: 12px;
                    font-weight: bold;
                }
            }

            .timeline-subtitle {
                font-weight: 300;
                font-style: italic;
                opacity: .4;
                margin-top: 16px;
                font-size: 11px;
            }
        }

        // Primary Timeline
        &.primary {
            .badge,
            .timeline-icon {
                background: $primary !important;
            }
        }


        // Success Timeline
        &.success {
            .badge,
            .timeline-icon {
                background: $cyan !important;
            }
        }

        // Dnager Timeline
        &.danger {
            .badge,
            .timeline-icon {
                background: $orange !important;
            }
        }

    }
}


.author{
	font-family: inherit;
	padding: 3em;
	text-align: center;
	width: 100%;
		color: white;
 
	a:link,
	a:visited{
		color: white;
		&:hover{
			text-decoration: none;
		}
	}
	.btn:link,
	.btn:visited{
		margin-top: 1em;
		text-decoration: none;
		display: inline-block;
		font-family: inherit;
		font-weight: 100;
		color: white;
		text-align: center;
		vertical-align: middle;
		user-select: none;
		background-color: black;
		padding: 1.5em 2rem;
		border-radius: 1em;
		transition: .5s all;
		&:hover,
		&:focus,
		&:active{
			background-color: lighten(black, 10%);
		}
	}
}