//
// avatar.scss
//

.avatar-xs {
  height: 1.5rem;
  width: 1.5rem;
}

.avatar-s {
  height: 2rem;
  width: 2rem;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar-lg {
  height: 6rem;
  width: 6rem;
}

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem;
}

.avatar-delete-btn{
  align-items: center;
  background-color: rgb(121, 121, 121);
  color: $white;
  display: flex;
  font-weight: $fw-medium;
  height: 100%;
  justify-content: center;
  width: 100%;
  border: solid 2px white;

  &:hover{
    background-color:$orange
  }
}

.avatar-title {
  align-items: center;
  background-color: $primary;
  color: $white;
  display: flex;
  font-weight: $fw-medium;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.avatar-group-core-add{
  margin-left: -12px;
}

.avatar-group-core{
  margin-left: -12px;
  &:hover{
    position: relative;
    transform: translateY(-2px);
  }
}

.avatar-delete  #avatar-delete-show{
  display: none!important;
}

.avatar-delete:hover #avatar-delete-show{
  display: block!important;
  
}

.avatar-delete2  #avatar-delete-show2{
  display: none!important;
}

.avatar-delete2:hover #avatar-delete-show2{
  display: block!important;
  
}

.avatar-delete3  #avatar-delete-show3{
  display: none!important;
}

.avatar-delete3:hover #avatar-delete-show3{
  display: block!important;
  
}


// avatar group
.avatar-group {
  padding-left: 12px;
  display: flex;
  flex-wrap: wrap;
  .avatar-group-item {
    border: 2px solid $card-bg;
    border-radius: 50%;
    transition: all 0.2s;
  }
}