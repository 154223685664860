
// 
// profile.scss
//

.circle-icon {
    position: relative;
    top: 4px;
    left: 45px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    font-size: 2rem;
    background: $primary;

    h3 {
        position: relative;
        color: $white;
        padding-top: 10px;

    }
}

.blue{
    color: $blue;
}

.orange{
    color: $orange;
}


