
//
// Select 2
//

.select2-container {

  .select2-selection--single {
    background-color: $input-bg;
    border-radius: 20px !important;
    border: 1px solid $gray-500;
    font-size: 12px !important;
    height: 38px !important;
    &:focus{
      outline: none;
      
    }

    .select2-selection__rendered {
      line-height: 36px !important;
      padding-left: $input-padding-x!important;
      color: $input-color !important;
    }

    .select2-selection__arrow {
      height: 34px !important;
      width: 34px !important;
      right: 3px!important;

      b{
        border-color: $gray-500 transparent transparent transparent;
        border-width: 6px 6px 0 6px;
      }
    }

    .select2-selection__placeholder{
      color: $secondary !important;
    }
  }
}

.select2-container--open {
  .select2-selection--single {

    .select2-selection__arrow {

      b{
        border-color: transparent transparent $gray-500 transparent !important;
        border-width: 0 6px 6px 6px !important;
      }
    }
  }
}

.select2-container--default {
  .select2-search--dropdown {
      padding: 10px !important;
      background-color: $dropdown-bg!important;
      .select2-search__field {
        font-size: 12px ;
          border: 1px solid  $gray-500;
          border-radius: 20px !important;
          background-color: $input-bg;
          color: $gray-600;
          outline: none;
      }
  }
  .select2-results__option--highlighted[aria-selected] {
      background-color: $primary;
      font-size: 12px;
  }
  .select2-results__option[aria-selected=true] {
      background-color: $dropdown-link-active-bg;
      color: $dropdown-link-active-color;
      font-size: 12px;
      &:hover {
          background-color: $primary;
          color: $white;
          font-size: 12px;
      }
  }
}

.select2-results__option {
  padding: 6px 12px !important;
  font-size: 12px !important;
}
.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered{
  padding-left: $input-padding-x !important;
}


.select2-dropdown {
  border: 1px solid $dropdown-border-color;
  background-color: $dropdown-bg;
  box-shadow: $box-shadow;
}

.select2-search {
  input{
    border: 1px solid $gray-300;
  }
}

.select2-container {
  .select2-selection--multiple {
    min-height: 38px !important;
    background-color: $input-bg !important;
    border: 1px solid $gray-500 !important;
    border-radius: 20px !important;
  
    .select2-selection__rendered {
      padding: 2px $input-padding-x !important;
    }
    .select2-search__field {
      border: 0;
      font-size: 12px;
      color: $input-color;
      &::placeholder{
          color: $input-color;
      }
  }
    .select2-selection__choice {
      background-color: $gray-200 !important;
      border: 1px solid $gray-300 !important;
      border-radius: 1px !important;
      padding: 0 7px !important;
    }
  }
}

.select2-container--default{
  &.select2-container--focus {
    .select2-selection--multiple{
      border-color: $gray-100;
    }
  }

  .select2-results__group{
    font-weight: $font-weight-semibold;
  }
}

.select2-search--inline {
  display: contents; /*this will make the container disappear, making the child the one who sets the width of the element*/
}

.select2-search__field:placeholder-shown {
  width: 100% !important; /*makes the placeholder to be 100% of the width while there are no options selected*/
}

// ajax select

.select2-result-repository__avatar{
    float: left;
    width: 60px;
    margin-right: 10px;
  img{
    width: 100%;
    height: auto;
    border-radius: 2px;
  }
}

.select2-result-repository__statistics{
  margin-top: 7px;
}

.select2-result-repository__forks, 
.select2-result-repository__stargazers, 
.select2-result-repository__watchers{
  display: inline-block;
  font-size: 11px;
  margin-right: 1em;
  color: $gray-500;

  .fa{
    margin-right: 4px;

    &.fa-flash{
      &::before{
        content: "\f0e7";
        font-family: 'Font Awesome 5 Free';
      }
    }
  }
}

.select2-results__option--highlighted{
  .select2-result-repository__forks, 
.select2-result-repository__stargazers, 
.select2-result-repository__watchers{
  color: rgba($white, 0.8);
}
}

.select2-result-repository__meta{
  overflow: hidden;
}


// templating-select

.img-flag{
  margin-right: 7px;
  height: 15px;
  width: 18px;
}


