.accordion-button-camps {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    color: #a6b0cf;
    text-align: left;
    background-color: transparent;
    border: 1px solid #32394e;
    border-radius: 0;
    overflow-anchor: none;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button-camps {
        -webkit-transition: none;
        transition: none; } }
    .accordion-button-camps.collapsed {
      border-bottom-width: 0; }
    .accordion-button-camps:not(.collapsed) {
      color: #4d63cf;
      background-color: rgba(85, 110, 230, 0.1); }
      .accordion-button-camps:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234d63cf'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
    .accordion-button-camps::after {
    position: relative;
    top: 30px;
      -ms-flex-negative: 0;
          flex-shrink: 0;
      width: 16px;
      height: 16px;
      margin-left: auto;
      content: "";
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23a6b0cf'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-size: 16px;
      -webkit-transition: -webkit-transform 0.2s ease-in-out;
      transition: -webkit-transform 0.2s ease-in-out;
      transition: transform 0.2s ease-in-out;
      transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        .accordion-button-camps::after {
          -webkit-transition: none;
          transition: none; } }
    .accordion-button-camps:hover {
      z-index: 2; }
    .accordion-button-camps:focus {
      z-index: 3;
      outline: 0;
      -webkit-box-shadow: none;
              box-shadow: none; }

.journey-step-success{
  padding-left: 10px;
  margin-left: 0.5em;
  background-color: $cyan;
  border-radius: 10px;
  max-height: 5px;
}

.journey-step-incomplete{
  padding-left: 10px;
  margin-left: 0.5em;
  background-color: grey;
  border-radius: 10px;
  max-height: 5px;
}


.drip-table{
  text-decoration: none;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding:0;
}

.italic{
  font-style: italic;
}

.table-btn{
  background-color: #F35A38;
}

.text-hover{
  color: white;
  transition: background-color .5s;
}
.text-hover:hover{
  color: $primary;
}

.text-hover-dark{
  color: $primary !important;
  transition: background-color .5s;
}
.text-hover-dark:hover{
  color: $orange !important;
}

.text-hover-link{
  color: $orange;
  transition: background-color .5s;
}
.text-hover-link:hover{
  color: $primary;
}


/* Kanban to do list */


.list-ul {
  margin: 0;
  padding: 0;
  list-style: none;

}

/* Style the list items */
.list-ul .list-li {
  cursor: pointer;
  position: relative;
  padding: 12px 40px 12px 40px;
  font-size: 14px;
  transition: 0.2s;

  /* make the list items unselectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Set all odd list items to a different color (zebra-stripes) */
.list-ul .list-li:nth-child(odd) {
  background: #eee;
}

/* Darker background-color on hover */
.list-ul .list-li:hover {
  background: #ddd;
}

/* When clicked on, add a background color and strike out text */
.list-ul .list-li.checked {
  background: $success;
  color: #fff;
}

/* Add a "checked" mark when clicked on */
.list-ul .list-li.checked::before {
  content: '';
  position: absolute;
  border-color: #fff;
  border-style: solid;
  border-width: 0 2px 2px 0;
  top: 10px;
  left: 16px;
  transform: rotate(45deg);
  height: 15px;
  width: 7px;
}

/* Style the close button */
.close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px 16px 12px 16px;
}

.close:hover {
  background-color: $orange;
  color: white;
}

/* Style the input */
.list-input {
  margin: 0;
  border: solid 1px $primary;
  border-radius: 0;
  width: 75%;
  padding: 10px;
  float: left;
  font-size: 16px;
}

.hori-scroll {
overflow-y: overlay;
}

.no-input{
  background-color: transparent;
  border-bottom: solid $primary 1px;
  border-top: none;
  border-left: none;
  border-right: none;
  text-align: center;
  border-radius: 0px;

}

.no-input[type="number"]::placeholder{
  text-align: center;
}

.percent-input{
  background-color: transparent;
  border: dashed 1px #e7e7e7;
  text-align: center;
  border-radius: 0px;

}

.percent-input[type="number"]::placeholder{
  text-align: center;
}

.with-page-break {
  page-break-before: always;
  page-break-after: always;
}

.lead-loder-name {
border: none;
background-color: transparent;
text-decoration: underline;
}

.table-truncate {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.weather-truncate {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



.report-cards{
  width: 100px;
}

.card-border-h49{
  height: 135px; 
  text-align: center; 
  border: solid 2px #f1967d ; 
  border-radius: 15px;
  background-color: white;
  transition: all .2s ease-in-out;
  cursor: crosshair;
}

.card-border-h49:hover{
  height: 135px; 
  text-align: center; 
  border: solid 2px #f1967d ; 
  border-radius: 15px;
  background-color: whitesmoke;
}

.card-border-pop-h49{
  height: 135px; 
  text-align: center; 
  border: solid 2px whitesmoke ; 
  border-radius: 15px;
  background-color: $orange;
  transition: all .2s ease-in-out;
  cursor: crosshair;
}

.card-border-pop-h49:hover{
  height: 135px; 
  text-align: center; 
  border: solid 2px whitesmoke ; 
  border-radius: 15px;
  background-color: #ee7f60 ;
}

.report-col{
width: 70%;
}

.stripped-button {
  border: none;
  background-color: transparent;
  text-decoration: none;
  }

//<!-- ============================================================== -->
//<!-- TIPPY TOOL TIP  -->
//<!-- ============================================================== -->

.tippy-box[data-theme~='funnel'] {
  background: rgb(116, 116, 116);
  font-size: 11px;
}

.tippy-box[data-theme~='funnel'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: rgb(116, 116, 116);
}

.tippy-box[data-theme~='gradient'] {
  background: rgb(0,30,59);
  background: linear-gradient(45deg, rgba(0,30,59,1) 45%, rgba(253,55,129,1) 100%);
  color: rgb(255, 255, 255);
}



//<!-- ============================================================== -->
//<!-- PRE LOADER -->
//<!-- ============================================================== -->

.hide{
  display: none!important;
}

$base-duration: 500ms;

*, *:before, *:after {
	box-sizing: border-box;
	outline: none;
}


.loader {
	position: relative;
	display: grid;
	grid-template-columns: 33% 33% 33%;
	grid-gap: 2px;
	width: 70px;
	height: 70px;
	
	> div {
		position: relative;
		width: 100%;
		height: 100%;
		background: #F35A38;
		transform: scale(0.0);
		transform-origin: center center;
		animation: loader 2s infinite linear;
		
		&:nth-of-type(7) {}
		
		&:nth-of-type(1),
		&:nth-of-type(5), 
		&:nth-of-type(9) {
			animation-delay: 0.4s;
		}
		
		&:nth-of-type(4),
		&:nth-of-type(8) {
			animation-delay: 0.2s;
		}
		
		&:nth-of-type(2),
		&:nth-of-type(6) {
			animation-delay: 0.6s;
		}
		
		&:nth-of-type(3) {
			animation-delay: 0.8s;
		}
	}
}

@keyframes loader {
	0%   { transform: scale(0.0); }
	40%  { transform: scale(1.0); }
	80%  { transform: scale(1.0); }
	100% { transform: scale(0.0); }
}

//<!-- ============================================================== -->
//<!-- TUTORIAL BOXES -->
//<!-- ============================================================== -->
.button-fade{
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
   -ms-animation: fadein 3s; /* Internet Explorer */
    -o-animation: fadein 3s; /* Opera < 12.1 */
       animation: fadein 3s;
}


.fade-in{
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
   -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
       animation: fadein 1s;
}


.box {
  width: 250px;

  .title {
    width: 100%;
    text-align: center;

    h1 {
      color: #001E3B;
      text-transform: uppercase;

        font: 700 3em/1 "Oswald", sans-serif;
        letter-spacing: 0;
        padding: .25em 0 .325em;
        display: block;
        margin: 0 auto;
        text-shadow: 0 0 80px rgba(255,255,255,.5);
    
    /* Clip Background Image */
    
        background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y;
        -webkit-background-clip: text;
        background-clip: text;
    
    /* Animate Background Image */
    
        -webkit-text-fill-color: transparent;
        -webkit-animation: aitf 80s linear infinite;
    
    /* Activate hardware acceleration for smoother animations */
    
        -webkit-transform: translate3d(0,0,0);
        -webkit-backface-visibility: hidden;
    }
  }
  

  .role {
    width: 100%;
    text-align: center !important;

    p {
      animation-delay: 1s;
      font-weight: 500;
      color: #001E3B;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 5px;
      text-align: center;
      -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein 2s; /* Firefox < 16 */
       -ms-animation: fadein 2s; /* Internet Explorer */
        -o-animation: fadein 2s; /* Opera < 12.1 */
           animation: fadein 2s;
    }
  }
}

/* Animate Background Image */

@-webkit-keyframes aitf {
	0% { background-position: 0% 50%; }
	100% { background-position: 100% 50%; }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.loading {
  $colors: #F35A38, #89cff0, #4682b4, #0f52ba, #000080;
  display: flex;

  .dot {
    position: relative;
    width: 1em;
    height: 1em;
    margin: 0.8em;
    border-radius: 50%;

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background: inherit;
      border-radius: inherit;
      animation: wave 2s ease-out infinite;
    }

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        background: nth($colors, $i);
        opacity: 1;

        &::before {
          animation-delay: $i * 0.2s;
        }
      }
    }
  }
}

@keyframes wave {
  50%,
  75% {
    transform: scale(2.5);
  }

  80%,
  100% {
    opacity: 0;
  }
}


.cursor {
  z-index: 10000;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid #f35a38;
  transition: all 200ms ease-out;
  position: fixed;
  pointer-events: none;
  left: 0;
  top: 0;
  transform: translate(calc(-50% + 15px), -50%);
}

.cursor2 {
  z-index: 10000;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #001E3B;
  opacity: .3;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: width .3s, height .3s, opacity .3s;
}

.hover {
  z-index: 10000;
  background-color: #1FB8C8;
  opacity: 0.5;
}

.cursorinnerhover {
  z-index: 10000;
  width: 40px;
  height: 40px;
  opacity: .5;
}

//<!-- ============================================================== -->
//<!-- FUNNEL -->
//<!-- ============================================================== -->

.card-new{
  background-color: white; cursor: grab;
}

.card-middle-aged{
  background-color: rgb(255, 235, 235); cursor: grab;
}

.card-archaic{
  background-color: rgb(255, 212, 212); cursor: grab;
}


.score-circle {
  align-items: center;
  background-color: transparent;
  border: 1px solid $info;
  display: flex;
  font-weight: $fw-medium;
  height: 100%;
  justify-content: center;
  width: 100%;
  padding: 0.8rem;
}

.task-circle {
  align-items: center;
  background-color: transparent;
  display: flex;
  color: $orange;
  border: 1px solid $orange;
  height: 100%;
  justify-content: center;
  width: 100%;
  padding: 0.2rem;
  font-size: 9px;
}

.task-circle:hover {
  color: white;
  background-color: $orange;
}


.notification-circle {
  align-items: center;
  background-color: $orange;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  padding: 0.2rem;
  font-size: 9px;
}

.notification-size {
  height: 1rem;
  width: 1rem;
}


.dropzone-hover{
  color: $primary !important;
}
.dropzone-hover:hover{
  color: $orange !important;
  border-bottom: 4px solid #F35A38!important;
}

.team-member{
  background-color: white;
  border-radius: 10px;
  transition: ease-in-out 0.2s;

  &:hover{
    background-color: rgb(238, 238, 238);
  }
}


/* Dropdown Button */
.dropbtn {
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
  background-color: #2980B9;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  left: 100px;
  top: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 10000;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;}

.nav-tabs-funnel {

  .nav-item {
    position: relative;
    color: $primary;
    .nav-link {
      border: none;
      color: $primary;



      &.active{
        color: white;
        background-color: #F35A38;
        border-bottom: 4px solid white;

        &:after{
          transform: scale(1);
        }
      }
      &:hover{
        color: #F35A38;
        background-color: #ffffff;
        border-bottom: 4px solid #F35A38;
      }  

    }
  }
}

.top-nav-item{
  a:link{
  color: $primary;
  font-weight: 400;
  } 
  a:visited{
    background-color: #F35A38!important;
    border-bottom: 4px solid white!important;
    color: rgb(255, 255, 255)!important;
  }
  a:hover{
    color: white;
    background-color: #F35A38;
    border-bottom: 4px solid white;
  }  
  a:active{
    color: white;
    background-color: #F35A38;
    border-bottom: 4px solid white;
  }

}

.sticky{
  position: fixed;
}

.nav-tool-bar{
  width: 100vw; 
  top: 70px; 
  z-index:100; 
  background-color: #e2e2e2; 
  margin-left: -25px; 
  overflow: hidden;
}

.card-bottom #bottom-show{
  display: none!important;
}

.card-bottom:hover #bottom-show{
  display: block!important;
}

.card-red-border{
  border: 1px solid #F35A38;
}

.card-beads-overflow{
list-style-type: none; 
flex-wrap: wrap; 
position: relative; 
top: 0px;
line-height: 0px; 
max-width: 170px; 
max-height: 20px; 
overflow-y: hidden;
}

.blue-overlay-full {
  position: fixed; 
  width: 100vw; 
  height: 100vh; 
  background-color: #1fb7c879; 
  z-index: 5000;
}

.blue-overlay-none {
  position: fixed; 
  width: 100vw; 
  height: 100vh; 
  background-color: #1fb7c879; 
  z-index: 10000;
}


.inner-clip {
  width: 50%;
  height: 50%;
  background: none;
  left: 25%;
  top: 25%;
  position: absolute;
}

.card-truncate {
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.save-bar {
  position: fixed; 
  top: 88%; 
  background-color: white; 
  min-width: 100%;
}

.card-email-truncate {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.drop-zone{
  width: 100%; 
  border: dashed 2px rgb(201, 201, 201); 
  height: 80px; 
  border-radius: 10px; 
  flex-wrap: wrap; 
  justify-content: space-around;
  overflow: hidden;
}

.funnel-column-overflow{
  max-height: 85%; 
  overflow-y:auto;
  overflow-x:hidden;
}

.beed-grey{
  background-color: #cecece94; 
  padding-top: 3px; 
  width: 10px; 
  border-radius: 20px; 
  margin-right: 5px; 
  border:solid 1px #cecece94;
}

.beed-grey-outline{
  padding-top: 3px; 
  width: 10px; 
  border-radius: 20px; 
  margin-right: 5px; 
  border:solid 1px #cecece94
}

.beed-blue{
  background-color: #1fb7c8; 
  padding-top: 3px; 
  width: 10px; 
  border-radius: 20px; 
  margin-right: 5px; 
  border:solid 1px #1fb7c8;
}

.beed-blue-outline{
  padding-top: 3px; 
  width: 10px; 
  border-radius: 20px; 
  margin-right: 5px; 
  border:solid 1px #1fb7c8
}




#card-list{
  background-color: rgb(255, 62, 4);
}

//<!-- ============================================================== -->
//<!-- SCALING INTERACTIONS -->
//<!-- ============================================================== -->

.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.03); }

//<!-- ============================================================== -->
//<!-- RESPONSIVE AREAS -->
//<!-- ============================================================== -->

.report-pos {
  justify-content: center;
}

.drawer-overflow{
  max-height: 600px;  
  overflow: auto; 
  overscroll-behavior: contain;
}

.funnel-height{
  height: 550px;  
  overflow: auto; 
}
.overlay-height{
  height: 600px;  
  position: absolute; 
  background-color: #ffffffb6; 
  width: 86%; 
  z-index: 1000;
}


@media (max-height: 900px) {
  .funnel-height{
    height: 500px; 
  }

  .overlay-height{
    height: 500px; 
  }
  
}

@media (max-height: 864px) {
  .funnel-height{
    height: 470px; 
  }

  .overlay-height{
    height: 470px; 
  }

  .save-bar {
    top: 86%;
  }
  
}

@media (max-height: 800px) {
  .funnel-height{
    height: 400px; 
  }

  .overlay-height{
    height: 400px; 
  }
  
}

@media (max-height: 768px) {
  .drawer-overflow{
    max-height: 400px;  
  }
  .funnel-height{
    height: 380px; 
  }
  .overlay-height{
    height: 380px; 
  }
  .save-bar {
    top: 84%;
  }
  
}

@media (max-height: 720px) {
  .funnel-height{
    height: 350px; 
  }
  .overlay-height{
    height: 350px; 
  }
  
}

@media (max-height: 650px) {
  .drawer-overflow{
    max-height: 300px;  
  }
  .funnel-height{
    height: 300px; 
  }

  .overlay-height{
    height: 300px; 
  }

  .save-bar {
    top: 83%;
  }
}

@media (max-height: 560px) {

  .funnel-height{
    height: 280px; 
  }
  .overlay-height{
    height: 280px; 
  }
}

@media (max-width: 990px) {
  .overlay-height{
    width: 100vw; 
  }
}

@media (max-width: 1400px) {
  .report-pos {
    justify-content: center;
  }
}

@media (max-width: 1850px) {
  .report-col{
    width: 75%;
    }
}

@media (max-width: 1730px) {
  .report-col{
    width: 90%;
    }
}

@media (max-width: 1500px) {
  .report-col{
    width: 100%;
    }
}



